const RouteNames = {
  Home: "/",
  SignIn: "/sign-in",
  SignUp: "/sign-up",
  Transaction: "/transaction",
  TransactionDetail: "/transaction",
  TransactionPending: "/transaction/pending",
  Token: "/token",
  TokenDetail: "/token",
  TokenId: "/token/token-id",
  Block: "/block",
  BlockDetail: "/block",
  AddressDetail: "/address",
  Uncles: "/uncles",
  Reorgs: "/reorgs",
  VerifiedContracts: "/verified-contracts",
  ContractAddressDetail: "/contract-address",
  PoolsAddress: "/pools-address",
};

export default RouteNames;
